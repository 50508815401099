/* global styles placeholder */

.header_logo {
  height: 20px;
  border-right: 2px solid white;
  padding-right: 15px;
  margin-right: 15px;
  cursor: pointer;
}

mth-reports-library,
mth-selfservereports-dashboard,
mth-selfservereports-request,
mth-selfservereports-monitor {
  width: 98%;
}

h1, h2, h3, h4, p, ul {
  margin: 0px;
}